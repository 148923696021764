import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import { eventProps, journalProps, searchJournalProps } from '../interfaces';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { Table } from 'antd';
import {
    AllJournalBreadcrumbItems, formData,
    showDate, parseDate, allJournalTableColumns
} from '../ManualJournalController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AllJournals.styles.scss';

const AllJournals = () => {

    const today = new Date();
    const [currentTab, setCurrentTab] = useState<string>('All');
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showSuccessModal1, setShowSuccessModal1] = useState<boolean>(false);
    const [searchData, setSearchData] = useState<searchJournalProps>(formData);
    const [mjData, setMjData] = useState<journalProps[]>([]);
    const [filteredMjData, setFilteredMjData] = useState<journalProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        //@ts-ignore
        window.mjDetails = undefined;
        //@ts-ignore
        window.redirectFrom = 'AllJournal';
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 2);
        let searchStr = "?search_by=PostingDate&value=";
        searchStr += `${parseDate(startDate)}_${parseDate(today)}&order_by=JournalID_DESC&limit=50`;
        getManualJournal(searchStr);
    }, [])

    const goToMJDetails = (rec: journalProps) => {
        //@ts-ignore
        window.mjDetails = rec;
        navigate('/ViewJournalReceipt');
        //console.log(rec, index, event.target)
    };

    const closeModal = () => {
        setShowSuccessModal(false);
    }

    const filterJournalList = (tab: string, data: journalProps[] = []) => {
        const tabName = tab === 'Posted' ? 'All' : tab;
        setCurrentTab(tabName);
        const dataToFilter = (data.length > 0) ? data : mjData;
        const filterdJournal = dataToFilter.filter(mj => {
            switch (tab) {
                case 'Drafts':
                    return mj.Status === 'Draft' || mj.Status === "";
                case 'Awaiting_Approval':
                    return mj.Status === 'Pending';
                case 'Posted':
                    return mj.Status === 'Posted';
                default:
                    return true;
            }
        }).map(mj => {
            mj.UploadDate = showDate(mj.UploadDate);
            mj.PostingDate = showDate(mj.PostingDate);
            return mj;
        });
        setFilteredMjData(filterdJournal);
    }


    const handleUpdate = (e: eventProps) => {
        const { name, value } = e.target;
        const newFromData = { ...searchData };
        // @ts-ignore
        newFromData[name] = value;
        setSearchData(newFromData);
        if (name === 'allJournal_filter') {
            filterJournalList(value);
        }
    }

    const clearFilter = () => {
        setSearchData(formData);
    }

    const searchManualJournal = () => {
        let searchStr = "?search_by=";
        if (searchData.search_value) {
            searchStr += `${searchData.search_by}&value=${searchData.search_value}`;
        } else {
            if (searchData.from_date && searchData.to_date) {
                searchStr += `PostingDate&value=${searchData.from_date}_${searchData.to_date}&order_by=JournalID_DESC&limit=40`;
            } else {
                alert('Please enter the search criteria properly.');
            }
        }
        getManualJournal(searchStr);
    }

    const getManualJournal = async (searchStr: string) => {
        if (searchStr) {
            setIsLoading(true);
            try {
                await fetch(`${endPoint.getMJ}${searchStr}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => {
                        const statusCode = response.status;
                        const data = response.json();
                        return Promise.all([statusCode, data]);
                    })
                    .then(([statusCode, data]) => {
                        setIsLoading(false);
                        if (statusCode === 200 && data.length > 0) {
                            setMjData(data);
                            filterJournalList('All', data);
                        }
                    });
            } catch (exception) {
                setIsLoading(false);
                console.log(exception);
            }
        } else {
            console.log('Search value is empty');
        }
    }


    return (
        <div id="all-journals-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15 pad-l-r-30">
                    <Breadcrumbs paths={AllJournalBreadcrumbItems} />
                    <div className="m-ap-btn plush">Create New</div>
                </div>

                <div className="m-ap-tabs m-ap-m-b-15">
                    <div className="m-ap-row">
                        <label className={`receipt-tab ${(currentTab === 'All') ? 'tab-active' : ''}`} onClick={() => filterJournalList('All')}>
                            All
                        </label>
                        <label className={`receipt-tab ${(currentTab === 'Drafts') ? 'tab-active' : ''}`} onClick={() => filterJournalList('Drafts')}>
                            Drafts
                        </label>
                        <label className={`receipt-tab ${(currentTab === 'Awaiting_Approval') ? 'tab-active' : ''}`} onClick={() => filterJournalList('Awaiting_Approval')}>
                            Awaiting Approval
                        </label>
                    </div>
                </div>

                <div className="m-ap-search m-ap-m-b-15 pad-l-r-30">
                    <div className="m-ap-row search-elements">
                        <div className="m-ap-input-f m-ap-dropdown">
                            <label >Search Journal </label>
                            <select name="search_by" id="search_by"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.search_by}>
                                <option value="">Search By</option>
                                <option value="account">Account ID</option>
                                <option value="AccountName">Account Name</option>
                                <option value="JournalID">Journal ID</option>
                            </select>
                        </div>
                        <div className="m-ap-input-f m-ap-search-f">
                            <label>&nbsp;</label>
                            <input type="text" placeholder="Begin your search"
                                id="search_value" name="search_value"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.search_value}
                            />
                        </div>
                        <div className="m-ap-input-f m-ap-date">
                            <label>Transaction Date</label>
                            <input type="date" placeholder="Begin your search"
                                id="from_date" name="from_date"
                                onChange={(e) => handleUpdate(e)}
                            />

                        </div>
                        <div className="m-ap-input-f m-ap-to">
                            <label>To</label>
                        </div>
                        <div className="m-ap-input-f m-ap-date">
                            <label>&nbsp;</label>
                            <input type="date" placeholder="Begin your search"
                                id="to_date" name="to_date"
                                onChange={(e) => handleUpdate(e)}
                            />
                        </div>
                        <div>
                            <button id="receipt-search">Search</button>
                            <button id="clear-filter">Clear Filter</button>
                        </div>
                        {/* <div className="m-ap-input-f m-ap-flex m-ap-action">
                            <div className="m-ap-btn m-ap-search m-ap-blue" id="receipt-search" onClick={searchManualJournal}>Search</div>
                            <div className="m-ap-btn" id="clear-filter" onClick={clearFilter}>Clear Filters</div>
                        </div> */}
                    </div>
                </div>
                <div className="m-ap-filter m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15 pad-l-r-30">
                    <div className="m-ap-title">All Journals ({mjData.length})</div>
                    <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                        <div className="m-ap-filter-text filter-status">Filter by : Status</div>
                        <div className="m-ap-input-f">
                            <select name="allJournal_filter" id="allJournal_filter"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.allJournal_filter}>
                                <option value="All">All</option>
                                <option value="Posted">Posted</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="pad-l-r-30">
                    <div className="m-ap-table-box">
                        <div className="m-ap-table">

                            <Table
                                columns={allJournalTableColumns}
                                dataSource={filteredMjData}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: () => goToMJDetails(record)
                                    };
                                }}

                            />



                            {/* <table>
                                <thead>
                                    <tr>
                                        <th className="m-ap-account-id"><input type="checkbox"></input></th>
                                        <th className="m-ap-account-id">Journal #</th>
                                        <th className="m-ap-account-type">Trans. Date</th>
                                        <th className="m-ap-account-name">Post Date</th>
                                        <th className="m-ap-active-to">Debit</th>
                                        <th className="m-ap-status">Credit</th>
                                        <th className="m-ap-action">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredMjData.map((mj, index) => (
                                            <tr key={`journal-list-${index}`}>
                                                <td className="m-ap-account-id"><input type="checkbox"></input></td>
                                                <td><span className="rec-id" onClick={() => goToMJDetails(mj)}>{mj.JournalID}</span></td>
                                                <td>{showDate(mj.UploadDate)}</td>
                                                <td>{showDate(mj.PostingDate)}</td>
                                                <td>{mj.NumberOfPostings}</td>
                                                <td>{mj.NumberOfPostings}</td>
                                                <td>{mj.Status}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AllJournals;





