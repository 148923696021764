import React, { useEffect, useState } from 'react';
import endPoint from 'config/urls';
import { Table } from 'antd';
import { Pagination } from 'antd';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import { eventProps, accountProps, searchAccountProps } from '../interfaces';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { AllAccountsBreadcrumbItems, formData, sampleAccount, showDate, allAccountsTableColumns, parseDate } from '../AccountController';
import './AllAccounts.styles.scss';

const AllAccounts = () => {

    // const today = new Date();
    const [searchData, setSearchData] = useState<searchAccountProps>(formData);
    const [accountData, setAccountData] = useState<accountProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // const goToReceiptDetail = (rec: receiptProps) => {
    //     //@ts-ignore
    //     window.receiptData = rec;
    //     navigate('/ViewReceipt');
    // };

    // useEffect(() => {
       
    //     let searchStr = "account?search_by=AccountID&value=A000001&limit=1&order_by=AccountID_DESC";
    //     // searchStr += `${parseDate(startDate)}_${parseDate(today)}&order_by=JournalID_DESC&limit=100`;
    //     searchAccount(searchStr);
    // }, [])


    const handleUpdate = (e: eventProps) => {
        const { name, value } = e.target;
        const newFromData = { ...searchData };
        // @ts-ignore
        newFromData[name] = value;
        setSearchData(newFromData);
    }

    const searchAccount = () => {
        const startUrl = " https://finance.demo.sonic.dev.coherent.global/v1/account";
        let searchStr = `?search_by=AccountID&value=${searchData.search_value}&limit=1&order_by=AccountID_DESC`;
        if (searchStr) {
            setIsLoading(true);
            try {
                fetch(`${startUrl}${searchStr}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response: any) => {
                        const statusCode = response.status;
                        const data = response.json();
                        return Promise.all([statusCode, data]);
                    })
                    .then(([statusCode, data]) => {
                        setIsLoading(false);
                        if (statusCode === 200 && data.length > 0) {
                            setAccountData(data);
                        }
                    });
            } catch (exception) {
                setIsLoading(false);
                console.log(exception);
            }
        } else {
            console.log('Search value is empty');
        }
    }

    const clearFilter = () => {
        setSearchData(formData);
        setAccountData([]);
    }

    return (
        <div id="all-account-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div className="m-ap-wrap">
                <div className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <Breadcrumbs paths={AllAccountsBreadcrumbItems} />
                    <div className="m-ap-btn plush">Create New</div>
                </div>

                <div className="m-ap-search m-ap-m-b-15">
                    <div className="m-ap-row">
                        <div className="m-ap-input-f m-ap-dropdown">
                            <select name="search_by" id="search_by"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.search_by}>
                                <option value="">Search By</option>
                                <option value="account">Account ID</option>
                                <option value="AccountName">Account Name</option>
                                <option value="JournalId">Journal ID</option>
                            </select>
                        </div>
                        <div className="m-ap-input-f m-ap-search-f">
                            <input type="text" placeholder="Begin your search"
                                id="search_value" name="search_value"
                                onChange={(e) => handleUpdate(e)}
                                value={searchData.search_value}
                            />
                        </div>
                        <div className="m-ap-input-f m-ap-flex m-ap-action">
                            <div className="m-ap-btn m-ap-search m-ap-blue" id="receipt-search" onClick={searchAccount}>Search</div>
                            <div className="m-ap-btn" id="clear-filter" onClick={clearFilter}>Clear Filters</div>
                        </div>
                    </div>
                </div>
                <div className="m-ap-filter m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <div className="m-ap-title">All Accounts ({accountData.length})</div>
                    {/* <div className="m-ap-title">All Accounts </div> */}
                    <div className="m-ap-filter-dropdown m-ap-flex m-ap-align-items-center">
                        <div className="m-ap-filter-text">Filter by : Account Type</div>
                        <div className="m-ap-input-f">
                            <select>
                                <option>All</option>
                                <option>Option text 1</option>
                                <option>Option text 2</option>
                                <option>Option text 3</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="m-ap-table-box">
                    <div className="m-ap-table">

                    <Table
                                columns={allAccountsTableColumns}
                                dataSource={accountData}
                                
                            />
                        {/* <table>
                            <thead>
                                <tr>
                                    <th className="m-ap-account-id">Account ID</th>
                                    <th className="m-ap-account-type">Account Type</th>
                                    <th className="m-ap-account-name">Account Name</th>
                                    <th className="m-ap-ledger-id">Ledger ID</th>
                                    <th className="m-ap-active-from">Active From</th>
                                    <th className="m-ap-active-to">Active To</th>
                                    <th className="m-ap-status">Status</th>
                                    <th className="m-ap-action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    accountData.AccountID && (
                                        <tr>
                                            <td>{accountData.AccountID}</td>
                                            <td>{accountData.Description}</td>
                                            <td>{accountData.Data}</td>
                                            <td>{accountData.LedgerID}</td>
                                            <td>{showDate(accountData.ActiveFrom)}</td>
                                            <td>{showDate(accountData.ActiveTo)}</td>
                                            <td>{accountData.Status}</td>
                                            <td>...</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table> */}
                    </div>
                    {/* <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                        <Pagination defaultCurrent={1} total={50} />
                    </div> */}
                </div>

                 {/* <div className="popup-box popup-msg">
                        <div className="row success-msg success-msg-desktop">
                            <div className="background-success display-contents">
                                <span className="glyphicon glyphicon-ok-circle success-icon"><i className="bi bi-check-circle"></i></span>
                                <span className="message"> Please select only one payee code at a time </span>
                                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                                <div className="dismiss-box">
                                    <span className="payment-timestamp">12:06 15/04/2021</span>
                                    &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                                    <span className="dismiss">Dismiss </span>
                                </div>
                            </div>
                        </div>
                    </div> */}

            </div>
        </div>
    )
}

export default AllAccounts;