import React, { useEffect, useState } from 'react';
import { useRoutes, usePath, navigate } from 'raviger';

import Page404 from 'layout/Page404';
import Login from 'Pages/Public/Login';
import Logout from 'Pages/Public/Logout';
import Dashboard from 'Pages/Dashboard';
import Policy from 'Pages/Policy';
import Claims from 'Pages/Claims';

import PolicyConfirmation from './Pages/Agent/PolicyConfirmation';

import PrivateLayout, { IPrivateLayout } from 'layout/PrivateLayout';
import { ILink } from 'layout/PrivateLayout/Navigation';

import CreatePolicy from './Pages/Products/CreatePolicy/CreatePolicy';
import GetStatement from './Pages/Products/getStatement/getStatement';
import Withdraw from './Pages/Products/Withdraw/Withdraw';
import Topup from './Pages/Products/Topup/Topup';
import Fundswitch from './Pages/Products/Fundswitch/Fundswitch';
import Loan from './Pages/Products/Loan/Loan';
import Pay from './Pages/Products/Pay/Pay';
import ViewReceipt from './Pages/Receipt/AccReceivable/ViewReceipt';

import ProductSelection from './Pages/MotorRisk/ProductSelection/ProductSelection';
import AgentDetails from './Pages/MotorRisk/AgentDetails/AgentDetails';
import OwnerDetails from './Pages/MotorRisk/OwnerDetails/OwnerDetails';
import VehicleDetails from './Pages/MotorRisk/VehicleDetails/VehicleDetails';
import DriverDetails from './Pages/MotorRisk/DriverDetails/DriverDetails';
import PlanDetails from './Pages/MotorRisk/PlanDetails/PlanDetails';
import BenefitDetails from './Pages/MotorRisk/BenefitDetails/BenefitDetails';
import UnderwritingDetails from './Pages/MotorRisk/UnderwritingDetails/UnderwritingDetails';
import CheckoutDetails from './Pages/MotorRisk/CheckoutDetails/CheckoutDetails';
import PaymentSuccess from './Pages/MotorRisk/PaymentSuccess/PaymentSuccess';

import AllAccounts from './Pages/Accounts/AllAccounts/AllAccounts';
import CreateNewAccount from './Pages/Accounts/CreateNewAccounts/CreateNewAccount';
import UploadFile from './Pages/FileUpload/Upload File/UploadFile.component';
import AllUpload from './Pages/FileUpload/AllUploads/AllUpload.component';
import Reports from './Pages/Report/ReportHome/Reports.component';
import ViewAccounts from 'Pages/Accounts/ViewAccounts.tsx/ViewAccounts.component';
import NoPeriod from './Pages/Accounting Period/NoPeriod/NoPeriod';
import AllPeriod from './Pages/Accounting Period/AllPeriod/AllPeriod.component';
// import AllPeriodcopy from './Pages/Accounting Period/AllPeriod/AllPeriodcopy.component';
import CreateNewPeriod from './Pages/Accounting Period/CreateNewPeriod/CreateNewPeriod.component.';
import NoLedger from 'Pages/Ledger/NoLedger/NoLedger';
import AllLedger from 'Pages/Ledger/AllLedger/AllLedger.component';
import CreateLedger from 'Pages/Ledger/CreateLedger/CreateLedger';
import AllJournals from './Pages/ManualJournal/AllJournal/AllJournals.component';
import CreateManualJournal from './Pages/ManualJournal/CreateJournal/CreateManualJournal';
import JournalReceipt from './Pages/ManualJournal/ViewJournal/ViewJournalReceipt';


interface IRoute extends ILink {
  component: any;
  public?: boolean;
  parentPath?: string;
}

const Router = () => {
  const path = usePath();
  const [user, setUser] = useState();

  useEffect(() => {
    const checkLogin = async () => {
      // @TODO Already logged-in
      const session = true;
      setUser(session);
      !session && navigate('/login');
    };

    checkLogin();
  }, [path]);

  useEffect(() => {
    if (user && path === '/') {
      navigate('/login');
    }
  }, [user, path]);

  const authenticate = (Component: any, params: IPrivateLayout) => {
    if (user) {
      return (
        <PrivateLayout title={params.title} path={params.path}>
          <Component {...params} />
        </PrivateLayout>
      );
    }
  };

  const getRoutes = () => {
    let routeMap: any = {};
    routes.forEach(
      (route: IRoute) =>
      (routeMap[route.path] = (params: any) =>
        route.public ? (
          <route.component />
        ) : (
          authenticate(route.component, {
            ...params,
            title: route.title,
            path: route.parentPath || route.path
          })
        ))
    );

    return routeMap;
  };

  return useRoutes(getRoutes());
};

export const routes: Array<IRoute> = [
  {
    path: '/dashboard',
    icon: 'widgets',
    title: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/Product',
    icon: 'trending-up',
    title: 'New Business',
    component: ProductSelection,
  },
  {
    path: '/policy',
    icon: 'dot-circle-alt',
    title: 'Policy 360',
    component: Policy
  },
  {
    path: '/underwriting',
    icon: 'assignment-o',
    title: 'Underwriting',
    component: Page404
  },
  {
    path: '/claims',
    // icon: 'receipt',
    // title: 'Claims',
    component: Claims
  },

  {
    path: '/ILP',
    icon: 'trending-up',
    title: 'Unit Link Insvestment',
    component: CreatePolicy,
    isParent: true
  },
  {
    path: '/CreatePolicy',
    icon: 'minus',
    title: 'Create Policy',
    component: CreatePolicy,
    children: true,
    ParentPath: '/ILP',
  },
  {
    path: '/Statement',
    icon: 'minus',
    title: 'Get Statement',
    component: GetStatement,
    children: true,
    ParentPath: '/ILP',
  },
  {
    path: '/Withdraw',
    icon: 'minus',
    title: 'Withdraw',
    component: Withdraw,
    children: true,
    ParentPath: '/ILP',
  },
  {
    path: '/Topup',
    icon: 'minus',
    title: 'Topup',
    component: Topup,
    children: true,
    ParentPath: '/ILP',
  },
  {
    path: '/Fundswitch',
    icon: 'minus',
    title: 'Fundswitch',
    component: Fundswitch,
    children: true,
    ParentPath: '/ILP',
  },
  {
    path: '/Loan',
    icon: 'minus',
    title: 'Loan',
    component: Loan,
    children: true,
    ParentPath: '/ILP',
  },
  {
    path: '/Pay',
    icon: 'minus',
    title: 'Pay',
    component: Pay,
    children: true,
    ParentPath: '/ILP',
  },

  {
    path: '/AccountsSection',
    icon: 'trending-up',
    title: 'Accounts',
    component: AllAccounts,
    isParent: true
  },
  {
    path: '/AllAccounts',
    icon: 'minus',
    title: 'All Accounts',
    component: AllAccounts,
    children: true,
    ParentPath: '/AccountsSection',
  },
  {
    path: '/CreateNewAccount',
    icon: 'minus',
    title: 'Create New Account',
    component:CreateNewAccount,
    children: true,
    ParentPath: '/AccountsSection',
  },
  {
    path: '/ViewAccounts',
    icon: 'minus',
    title: 'ViewAccount',
    component:ViewAccounts,
    children: true,
    ParentPath: '/AccountsSection',
  },

  {
    path: '/JournalsSection',
    icon: 'trending-up',
    title: 'Manual Journal',
    component: AllJournals,
    isParent: true
  },
  {
    path: '/AllJournals',
    icon: 'minus',
    title: 'All Journals',
    component: AllJournals,
    children: true,
    ParentPath: '/JournalsSection',
  },
  {
    path: '/CreateManualJournal',
    icon: 'minus',
    title: 'Create Journal',
    component: CreateManualJournal,
    children: true,
    ParentPath: '/JournalsSection',
  },
  {
    path: '/ViewJournalReceipt',
    component: JournalReceipt,
  },
  {
    path: '/ViewReceipt',
    component: ViewReceipt
  },

  {
    path: '/PeriodSection',
    icon: 'trending-up',
    title: 'Accounting Period',
    component: CreateNewPeriod,
    isParent: true
  },
  {
    path: '/CreateNewPeriod',
    icon: 'minus',
    title: 'Create New Periods',
    component: CreateNewPeriod,
    children: true,
    ParentPath: '/PeriodSection',
  },
  {
    path: '/NoPeriod',
    icon: 'minus',
    title: 'No Periods',
    component: NoPeriod,
    children: true,
    ParentPath: '/PeriodSection',
  },
  {
    path: '/AllPeriod',
    icon: 'minus',
    title: 'All Periods',
    component: AllPeriod,
    children: true,
    ParentPath: '/PeriodSection',
  },
  
  {
    path: '/LedgerSection',
    icon: 'trending-up',
    title: 'Ledger',
    component: AllLedger,
    isParent: true
  },
  {
    path: '/AllLedger',
    icon: 'minus',
    title: 'All Ledgers',
    component: AllLedger,
    children: true,
    ParentPath: '/LedgerSection',
  },
  {
    path: '/NoLedger',
    icon: 'minus',
    title: 'No Ledger',
    component: NoLedger,
    children: true,
    ParentPath: '/LedgerSection',
  },
  {
    path: '/CreateLedger',
    icon: 'minus',
    title: 'Create New Ledger',
    component: CreateLedger,
    children: true,
    ParentPath: '/LedgerSection',
  },
  // {
  //   path: '/UploadFile',
  //   icon: 'receipt',
  //   title: 'Upload File',
  //   component: UploadFile,
  //   ParentPath: '/LedgerSection',
  // },
  {
    path: '/UploadFile',
    icon: 'receipts',
    title: 'Upload File',
    component: UploadFile,
    children: true,
    ParentPath: '/LedgerSection',
  },
  {
    path: '/AllUpload',
    icon: 'receipts',
    title: 'All Uploads',
    component: AllUpload,
    children:true,
    ParentPath: '/LedgerSection',
  },

  {
    path: '/AgentDetails',
    component: AgentDetails,
  },
  {
    path: '/OwnerDetails',
    component: OwnerDetails,
  },
  {
    path: '/VehicleDetails',
    component: VehicleDetails,
  },
  {
    path: '/DriverDetails',
    component: DriverDetails,
  },
  {
    path: '/PlanDetails',
    component: PlanDetails,
  },
  {
    path: '/BenefitDetails',
    component: BenefitDetails,
  },
  {
    path: '/UnderwritingDetails',
    component: UnderwritingDetails,
  },
  {
    path: '/CheckoutDetails',
    component: CheckoutDetails,
  },
  {
    path: '/Payment',
    component: PaymentSuccess
  },
  {
    path: '/policyConfirmation',
    component: PolicyConfirmation
  },
  
  {
    path: '/profile',
    component: Page404
  },
  {
    path: '/settings',
    component: Page404
  },
  {
    path: '/login',
    public: true,
    component: Login
  },
  {
    path: '/logout',
    public: true,
    component: Logout
  }
];

export default Router;
